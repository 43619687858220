<template>
	<div style="border: 1px solid #ccc;padding:15px;background-color: #f5f5f5;">
		<div style="padding: 15px;background-color: #fff;">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">产品名称：</label>
				<el-input placeholder="请输入产品名称" style="width:90%;" v-model="productdata.title"></el-input>
			</div>
			<div style="display: flex;margin: 20px 0;">
				<label style="margin-right: 10px;line-height:40px;">产品描述：</label>
				<el-input type="textarea" :rows="2" style="width: 60%;" placeholder="请输入产品描述内容"
					v-model="productdata.description">
				</el-input>
			</div>
			<div style="display: flex;margin: 20px 0;">
				<label style="margin-right: 10px;line-height:40px;">产品分类：</label>
				<el-select @change="chatype" v-model="typevalue" :placeholder="typesname">
					<el-option v-for="item in typeoptions" :key="item.id" :value="item.id" :label="item.name">
					</el-option>
				</el-select>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">产品图片：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" :on-success="upload">
					<img v-if="productdata.imgurl" :src="productdata.imgurl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">配色图片：</label>
				<el-upload class="avatar-uploader"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :action="$ips+'/common/upfile'" :on-success="upload1">
					<img v-if="productdata.matchingurl" :src="productdata.matchingurl" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:240px;">场景图片：</label>
				<div v-for="(item,index) in imgslist" style="position: relative;width:180px;margin-top: 20px;">
					<i class="el-icon-error"
						style="position: absolute;z-index: 2;right:10px;top:10px;color: red;font-size: 20px;"
						@click="delimg(item.id)"></i>
					<img v-if="item.imgurl" :src="item.imgurl" class="avatar" style="position: absolute;z-index: 1;">
				</div>
				<el-upload class="avatar-uploader" v-if="imgslist.length <5"
					style="border: 1px solid #ebebeb;margin: 20px;padding: 5px;border-radius:15px;"
					:show-file-list="false" :limit="5" :action="$ips+'/common/upfile'" :on-success="upload2">
					<i class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-button type="primary" style="margin: 15px 0;" @click="addnews">编辑产品</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				typevalue: '',
				imgslist: [], // 场景图
				productdata: {
					matchingurl: '', //配色图片
					typeid: '', //产品分类id
					description: '', //产品简介
					title: '', //产品名称
					imgurl: '', //产品链接
					updatatime: ''
				},
				typeoptions: [],
				typesname:'',
				editor: null,
				html: '',
				toolbarConfig: {},
				mode: 'default', // or 'simple'
			}
		},
		methods: {
			chatype(e) {
				this.productdata.typeid = e
			},
			delimg(e) {
				// 删除产品图片
				this.$post("/productflooring/delimgs", {
					id: e
				}).then(res => {
					this.getimgs()
				})
			},
			addnews() {
				// 新增产品
				let that = this
				console.log(this.productdata);
				if (this.productdata.title == "" || this.productdata.description == '') {
					console.log("请填写产品名称或产品描述内容");
					that.$message({
						showClose: true,
						message: '请填写产品名称或产品描述内容',
						type: 'error'
					});
				} else if (this.productdata.imgurl == '') {
					// 未上传图片
					console.log("请上传产品图片");
					this.$message({
						showClose: true,
						message: '请上传产品图片',
						type: 'error'
					});
				} else if (this.productdata.typeid == '') {
					// 未上传选择产品分类
					console.log("请选择产品分类");
					this.$message({
						showClose: true,
						message: '请选择产品分类',
						type: 'error'
					});
				} else {
					let that = this
					console.log("确定要编辑吗");
					this.productdata.updatatime = this.$gettime()
					that.$post("/productflooring/updataproduct", that.productdata).then(res => {
						if (res.data == '编辑成功') {
							that.$message({
								type: 'success',
								message: '编辑成功!'
							});
						} else {
							that.$message({
								type: 'error',
								message: '编辑失败!'
							});
						}
					})
				}
			},
			upload(e) {
				console.log(e);
				if (e.data.url) {
					this.productdata.imgurl = e.data.url
				}
			},
			upload1(e) {
				console.log(e);
				if (e.data.url) {
					this.productdata.matchingurl = e.data.url
				}
			},
			upload2(e) {
				console.log(e);
				if (e.data.url) {
					this.$post("/productflooring/addimgs", {
						pid: this.productdata.id,
						imgurl: e.data.url,
						updatatime: this.$gettime()
					}).then(res1 => {
						this.getimgs()
					})
				}
			},
			getimgs() {
				this.$get("/productflooring/getimgs", {
					pid: this.productdata.id
				}).then(res => {
					this.imgslist = res.data
				})
			}
		},
		mounted() {
			// 模拟 ajax 请求，异步渲染编辑器
			let that = this
			this.$get("/typesflooring/gettype").then(res=>{
				this.typeoptions = res.data
			})
			this.$get('/productflooring/getproduct', {
				id: this.$route.params.id
			}).then(res => {
				this.productdata = res.data[0]
				this.$get("/typesflooring/gettype",{
					id:this.productdata.typeid
				}).then(res1=>{
					console.log(res1);
					that.typesname = res1.data[0].name
					console.log(that.typesname);
				})
				this.getimgs()
			})

		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>